<template>
  <MainStyled :isSettingsExpanded="isSettingsExpanded">
    <TalpaLoaderWrapper v-if="isLoading" />
    <template v-else>
      <Subheader class="subheader" :hasScrolled="hasScrolled" />
      <transition name="fade-in-left">
        <MapSettings
          v-if="displayOverlay"
          @toggleSettingsExpanded="toggleSettingsExpanded"
          @toggleSettings="toggleSettings"
          @selectedHeatmapData="activitiesList"
          :localSelectedSettings="localSelectedSettings"
          :assetIds="assetIds"
        />
      </transition>
      <MapStyled>
        <MapOrganism
          :sites="sites"
          :mapToggleSettings="localSelectedSettings"
          :isSettingsExpanded="isSettingsExpanded"
          :selectedActivities="selectedActivities"
          :selectedHexOption="selectedKPI"
          :tileSets="tileSets"
          :assets="assetsSelected"
        />
      </MapStyled>
    </template>
  </MainStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import MapOrganism from './Main/Map'
import Subheader from './Main/Subheader'
import MapSettings from './Main/Settings/MapSettings'
import { TalpaLoaderWrapper } from '@common/components'

import { useAssetStore } from '@/stores/assets'

import ASSETS_MINIMAL_QUERY from '#/graphql/operations/assets/minimals/assetsMinimal.gql'
import MY_ASSETS_SITE_WITH_TILES_QUERY from '#/graphql/operations/assets/minimals/myAssetsSiteWithTiles.gql'
import MY_ASSETS_COORDINATES_QUERY from '#/graphql/operations/assets/minimals/myAssetsCoordinates.gql'
import SELECTED_SETTINGS_QUERY from '#/graphql/local/selectedSettings.gql'

const MapStyled = styled('div')`
  height: 85vh;
  width: 100%;
`
const MainStyled = styled('div')`
  width: 100%;
  height: calc(100% - 5px);
  overflow: hidden;
  transition: all 0.5s linear;
`

export default {
  inject: ['permissions'],
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  components: {
    MapStyled,
    MapOrganism,
    MapSettings,
    Subheader,
    MainStyled,
    TalpaLoaderWrapper,
  },
  props: {
    hasScrolled: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    const client = this.$apollo.provider.defaultClient
    const { selectedSettings } = client.readQuery({
      query: SELECTED_SETTINGS_QUERY,
    })
    this.localSelectedSettings = selectedSettings === null ? this.localSelectedSettings : selectedSettings
    if (selectedSettings) {
      this.selectedKPI = get(selectedSettings, 'selectedDimension', null)
      this.selectedActivities = get(selectedSettings, 'selectedActivityList', null)
    }
  },
  created() {
    this.$root.$on('calendarActive', this.calendarActive)
  },
  data() {
    return {
      assetsSiteMap: new Map(),
      assetsLastUpdateMap: new Map(),
      selectedSiteID: null,
      isSettingsExpanded: false,
      displayOverlay: true,
      selectedActivities: [],
      selectedKPI: null,
      localSelectedSettings: {
        showLegends: false,
        showGeofences: false,
        showLivePositions: false,
        useStreetsStyle: false,
        selectedGeofence: {
          id: 'none',
          label: this.$t('noFilter'),
        },
        selectedDimension: {
          id: 'none',
          label: 'None',
        },
        selectedActivityList: [],
      },
    }
  },
  computed: {
    isLoading() {
      return this.$apollo.loading
    },
    assetsSelected() {
      const assets = this.assetStore.assetsSelectedInMap()
      return assets.map(asset => {
        const site = this.assetsSiteMap.get(asset.id)
        const lastUpdateData = this.assetsLastUpdateMap.get(asset.id)
        return {
          ...asset,
          site: site,
          lastPosition: lastUpdateData,
        }
      })
    },
    assetIds() {
      return this.assetsSelected.map(({ id }) => id)
    },
    sites() {
      const assetsSites = this.assetsSelected?.map(({ site }) => site).filter(site => site?.talpalyticsName)
      if (assetsSites.length > 0) {
        const sites = uniqBy(assetsSites, 'siteId')
        const siteNames = []
        sites.forEach(site => {
          if (site.talpalyticsName) {
            siteNames.push(site.talpalyticsName)
          }
        })
        return siteNames
      }
      return []
    },
    tileSets() {
      if (!this.assetsSelected) return []

      const assetsSites = this.assetsSelected?.map(({ site }) => site).filter(site => site?.talpalyticsName && site?.tileSetID)

      if (assetsSites?.length > 0) {
        const sites = uniqBy(assetsSites, 'siteId')
        const siteNamesWithTiles = sites
          .filter(site => site.tileSetID)
          .map(site => ({
            name: site.talpalyticsName,
            tileSetId: site.tileSetID,
          }))

        return siteNamesWithTiles
      }
      return []
    },
  },
  methods: {
    toggleSettingsExpanded(isExpanded) {
      this.isSettingsExpanded = isExpanded
    },
    toggleSettings(settings) {
      this.localSelectedSettings = settings
    },
    calendarActive(isCalendarActive) {
      if (isCalendarActive) {
        this.displayOverlay = false
        this.toggleSettingsExpanded(false)
      } else {
        this.displayOverlay = true
      }
    },
    activitiesList(data) {
      this.localSelectedSettings.selectedDimension = data.kpi
      this.localSelectedSettings.selectedActivityList = data.activity
      if (data.kpi && data.kpi.id === 'none') {
        this.localSelectedSettings.showLegends = false
      }
      this.selectedActivities = data.activity
      this.selectedKPI = data.kpi
    },
  },
  apollo: {
    assets: {
      query: ASSETS_MINIMAL_QUERY,
      variables: {
        where: {
          isVisible: {
            in: [true],
          },
        },
      },
      result({ data }) {
        this.assetStore.allAssets = data?.assets ?? []
      },
    },
    myAssetsSite: {
      query: MY_ASSETS_SITE_WITH_TILES_QUERY,
      result({ data }) {
        this.assetsSiteMap = new Map(data.myAssetsSite.map(asset => [asset.assetId, asset]))
      },
    },
    myAssetsLastUpdate: {
      query: MY_ASSETS_COORDINATES_QUERY,
      result({ data }) {
        this.assetsLastUpdateMap = new Map(data.myAssetsLastUpdate.map(asset => [asset.assetId, asset]))
      },
    },
  },
}
</script>
