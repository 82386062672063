var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SubheaderStyled', {
    class: {
      scrolled: _vm.hasScrolled
    }
  }, [_c('TimepickerOrganism', {
    attrs: {
      "maxSelectableIntervalInDays": 92
    }
  }), _c('AssetSelector')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }