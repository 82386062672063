var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MapSettingTogglesStyled', [_c('span', {
    staticClass: "header"
  }, [_vm._v(" " + _vm._s(_vm.$t('map.mapSettings')) + " ")]), _c('OptionsStyled', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('map.legend')) + " ")]), _c('SimplePopover', {
    attrs: {
      "disabled": !_vm.canToggleLegend,
      "popoverContent": _vm.$t('map.info')
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c('OnOffToggle', {
          attrs: {
            "id": "showLegends",
            "disabled": _vm.canToggleLegend,
            "value": _vm.localSelectedSettings.showLegends
          },
          on: {
            "toggle": function toggle($event) {
              return _vm.toggleOptions('showLegends');
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "icon",
      fn: function fn() {
        return [_c('AlertTriangleIcon', {
          attrs: {
            "size": "1.2x"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _vm.geofenceReadPermission ? _c('OptionsStyled', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('map.geofences')) + " ")]), _c('OnOffToggle', {
    attrs: {
      "id": "showGeofences",
      "value": _vm.localSelectedSettings.showGeofences
    },
    on: {
      "toggle": function toggle($event) {
        return _vm.toggleOptions('showGeofences');
      }
    }
  })], 1) : _vm._e(), !_vm.hideLastPositionToggle ? _c('OptionsStyled', [_c('span', [_vm._v(" " + _vm._s(_vm.$tc('geofence', 1)) + " ")]), _c('OnOffToggle', {
    attrs: {
      "id": "showLivePositions",
      "value": _vm.localSelectedSettings.showLivePositions
    },
    on: {
      "toggle": function toggle($event) {
        return _vm.toggleOptions('showLivePositions');
      }
    }
  })], 1) : _vm._e(), _c('OptionsStyled', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('map.satelliteStyle')) + " ")]), _c('OnOffToggle', {
    attrs: {
      "id": "useStreetsStyle",
      "value": !_vm.localSelectedSettings.useStreetsStyle
    },
    on: {
      "toggle": function toggle($event) {
        return _vm.toggleOptions('useStreetsStyle');
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }