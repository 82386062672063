<template>
  <MapSettingsStyled :isSettingsExpanded="isSettingsExpanded">
    <div class="settings">
      <transition name="fade" mode="out-in">
        <SettingsExpandToggleStyled @click.stop="toggleSettingsExpanded()" :key="'icon'">
          <ChevronLeftIcon v-if="isSettingsExpanded" />
          <LayersIcon v-else />
        </SettingsExpandToggleStyled>
      </transition>
      <transition-group name="fade-in-left">
        <div class="header" v-if="isSettingsExpanded" :key="'header'">{{ $t('map.heatmap') }}</div>
        <SettingsDetails v-if="isSettingsExpanded" :key="'settings'">
          <HeatMapSettings
            @selectedActivities="heatMapData"
            :localSelectedSettings="localSelectedSettings"
            :assetIds="assetIds"
            @setToggleValues="setValue"
          />
          <MapSettingToggles :localSelectedSettings="localSelectedSettings" @setToggleValues="setValue" />
        </SettingsDetails>
      </transition-group>
    </div>
  </MapSettingsStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import { ChevronLeftIcon, LayersIcon } from 'vue-feather-icons'
import { flexCenter, buttonReset } from '@styles/mixins'

import HeatMapSettings from './HeatMapSettings'
import MapSettingToggles from './MapSettingToggles'

import SELECTED_SETTINGS_QUERY from '#/graphql/local/selectedSettings.gql'

const MapSettingsStyled = styled('div')`
  position: absolute;
  max-width: 14rem;
  background: ${props => chroma(props.theme.colors.solidBG).alpha(0.9).css()};
  z-index: 101;
  transition: all 0.5s linear;
  height: 85vh;
  backdrop-filter: ${props => (props.isSettingsExpanded ? 'blur(10px)' : 'blur(20px)')};
  .header {
    font-weight: 200;
  }
  .settings {
    padding: 1rem;
  }
`
const SettingsExpandToggleStyled = styled('div')`
  ${buttonReset}
  ${flexCenter}
  cursor: pointer;
  position: absolute;
  right: -1.1rem;
  color: ${p => p.theme.colors.primary};
  background-color: ${props => chroma(props.theme.colors.solidBG).alpha(0.8).css()};
  border-radius: 50%;
  width: 2.2rem;
  height: 2.2rem;
  transition: all 0.25s ease;
  z-index: 101;
  &.active {
    background-color: ${props => chroma(props.theme.colors.solidBG).alpha(0.2).css()};
  }
`

const SettingsDetails = styled('div')`
  height: auto;
  font-size: 0.8rem;
  display: grid;
  margin-top: 0.2rem;
  padding-top: 1rem;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr 1fr;
  grid-template-areas:
    'overlayer'
    'map-setting';
  transition: opacity 0.25s ease;
  .subheader {
    opacity: 0.5;
  }
`

export default {
  props: {
    localSelectedSettings: {
      type: Object,
    },
    assetIds: {
      type: Array,
      required: true,
    },
  },
  components: {
    MapSettingsStyled,
    SettingsExpandToggleStyled,
    ChevronLeftIcon,
    LayersIcon,
    SettingsDetails,
    HeatMapSettings,
    MapSettingToggles,
  },
  data() {
    return {
      isSettingsExpanded: false,
    }
  },
  methods: {
    toggleSettingsExpanded() {
      this.isSettingsExpanded = !this.isSettingsExpanded
      this.$emit('toggleSettingsExpanded', this.isSettingsExpanded)
    },
    setValue(name, newValue) {
      const localSelectedSettings = { ...this.localSelectedSettings }
      localSelectedSettings[name] = newValue
      this.updateSettings(localSelectedSettings)
      this.$emit('toggleSettings', localSelectedSettings)
    },
    updateSettings(args) {
      const client = this.$apollo.provider.defaultClient
      client.writeQuery({
        query: SELECTED_SETTINGS_QUERY,
        data: { selectedSettings: args },
      })
    },
    heatMapData(kpi, activity) {
      const localSelectedSettings = { ...this.localSelectedSettings }
      localSelectedSettings.selectedDimension = kpi
      localSelectedSettings.selectedActivityList = activity
      if (kpi && kpi.id === 'none') {
        localSelectedSettings.showLegends = false
      }
      this.updateSettings(this.selectedSettingsMapped)
      this.$emit('selectedHeatmapData', { kpi, activity })
    },
  },
}
</script>
