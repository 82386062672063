<template>
  <MapSettingTogglesStyled>
    <span class="header"> {{ $t('map.mapSettings') }} </span>
    <OptionsStyled>
      <span> {{ $t('map.legend') }} </span>
      <SimplePopover :disabled="!canToggleLegend" :popoverContent="$t('map.info')">
        <template v-slot:trigger>
          <OnOffToggle
            id="showLegends"
            :disabled="canToggleLegend"
            :value="localSelectedSettings.showLegends"
            @toggle="toggleOptions('showLegends')"
          />
        </template>
        <template v-slot:icon>
          <AlertTriangleIcon size="1.2x" />
        </template>
      </SimplePopover>
    </OptionsStyled>
    <OptionsStyled v-if="geofenceReadPermission">
      <span> {{ $t('map.geofences') }} </span>
      <OnOffToggle id="showGeofences" :value="localSelectedSettings.showGeofences" @toggle="toggleOptions('showGeofences')" />
    </OptionsStyled>
    <OptionsStyled v-if="!hideLastPositionToggle">
      <span> {{ $tc('geofence', 1) }} </span>
      <OnOffToggle id="showLivePositions" :value="localSelectedSettings.showLivePositions" @toggle="toggleOptions('showLivePositions')" />
    </OptionsStyled>
    <OptionsStyled>
      <span> {{ $t('map.satelliteStyle') }} </span>
      <OnOffToggle id="useStreetsStyle" :value="!localSelectedSettings.useStreetsStyle" @toggle="toggleOptions('useStreetsStyle')" />
    </OptionsStyled>
  </MapSettingTogglesStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { OnOffToggle } from '@common/components'
import get from 'lodash/get'
import SimplePopover from '@common/components/src/SimplePopover'
import { AlertTriangleIcon } from 'vue-feather-icons'
import permissionsMixin from '@/mixins/permissions'

const MapSettingTogglesStyled = styled('div')`
  grid-area: map-setting;
`
const OptionsStyled = styled('div')`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 2.1rem;
  padding-top: 1.5rem;

  span {
    opacity: 0.5;
  }
`

export default {
  mixins: [permissionsMixin],
  props: {
    localSelectedSettings: {
      type: Object,
      required: true,
    },
  },
  components: {
    MapSettingTogglesStyled,
    OnOffToggle,
    OptionsStyled,
    SimplePopover,
    AlertTriangleIcon,
  },
  data() {
    return {
      hideLastPositionToggle: true,
    }
  },
  computed: {
    canToggleLegend() {
      return get(this.localSelectedSettings, 'selectedDimension.id', null) === 'none'
    },
  },
  methods: {
    toggleOptions(id) {
      this[id] = !this[id]
      this.$emit('setToggleValues', id, this[id])
    },
  },
}
</script>
