var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HeatMapSettingsStyled', [_c('span', {
    staticClass: "subheader"
  }, [_vm._v(" " + _vm._s(_vm._f("capitalize")(_vm.$tc('dimension', 1))) + ": ")]), _c('BasicSelect', {
    key: 'select_kpi',
    staticClass: "select",
    attrs: {
      "options": _vm.availableAssetDimensions,
      "selected": _vm.selectedKPI
    },
    on: {
      "change": _vm.setSelectedKpiOption
    }
  }), _c('span', {
    staticClass: "subheader"
  }, [_vm._v(" " + _vm._s(_vm._f("capitalize")(_vm.$t('activity'))) + ": ")]), _c('SimplePopover', {
    staticClass: "wrapper",
    attrs: {
      "disabled": !_vm.isDisabled,
      "popoverContent": _vm.$t('map.info')
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c('multiselect', {
          staticClass: "activity-multi-select",
          class: {
            disabled: _vm.isDisabled
          },
          attrs: {
            "placeholder": _vm._f("capitalize")(_vm.$t('selects.activities')),
            "value": _vm.selectedActivities,
            "select-label": '',
            "selected-label": '',
            "deselect-label": '',
            "options": _vm.selectableActivities,
            "multiple": true,
            "track-by": "id",
            "hide-selected": false,
            "custom-label": _vm.customLabel,
            "close-on-select": false,
            "disabled": _vm.isDisabled,
            "taggable": false,
            "open-direction": "bottom"
          },
          on: {
            "select": function select($event) {
              return _vm.setSelectedActivityOption($event);
            },
            "remove": function remove($event) {
              return _vm.setSelectedActivityOption($event);
            }
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(scope) {
              return _c('span', {
                staticClass: "checkbox-label"
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: scope.option.checked,
                  expression: "scope.option.checked"
                }],
                staticClass: "align",
                attrs: {
                  "type": "checkbox",
                  "id": "checkbox"
                },
                domProps: {
                  "checked": Array.isArray(scope.option.checked) ? _vm._i(scope.option.checked, null) > -1 : scope.option.checked
                },
                on: {
                  "focus": function focus($event) {
                    $event.preventDefault();
                  },
                  "change": function change($event) {
                    var $$a = scope.option.checked,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(scope.option, "checked", $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(scope.option, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(scope.option, "checked", $$c);
                    }
                  }
                }
              }), _c('span', {
                staticClass: "checkmark"
              }), _vm._v(" " + _vm._s(scope.option.label) + " ")]);
            }
          }, {
            key: "caret",
            fn: function fn(_ref) {
              var toggle = _ref.toggle;
              return _c('ChevronDownIcon', {
                staticClass: "multiselect__select caretIcon",
                on: {
                  "mousedown": function mousedown($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    return toggle.apply(null, arguments);
                  }
                }
              });
            }
          }])
        })];
      },
      proxy: true
    }, {
      key: "icon",
      fn: function fn() {
        return [_c('AlertTriangleIcon', {
          attrs: {
            "size": "1.2x"
          }
        })];
      },
      proxy: true
    }])
  }), _vm.selectedActivities.length ? _c('div', [_c('span', {
    staticClass: "subheader"
  }, [_vm._v(" " + _vm._s(_vm._f("capitalize")(_vm.$tc('showUnknowns', 1))) + ": ")]), _c('BasicSelect', {
    key: 'select_geofence',
    staticClass: "wrapper",
    attrs: {
      "options": _vm.cycleGeofenceData,
      "selected": _vm.selectedGeofence
    },
    on: {
      "change": _vm.setSelectedGeofenceOption
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }