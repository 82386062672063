<template>
  <MapStyled>
    <div class="map-container" ref="map" />
    <transition name="slide-fade" mode="out-in">
      <MessageBox v-if="message">
        <div class="message">
          <div>{{ this.$tc(message) }}</div>
          <LoadingDots class="dots" v-if="showLoadingDots" />
        </div>
        <CloseButtonAtom class="close-button" v-if="!showLoadingDots" @close="closeMessageBox" />
      </MessageBox>
    </transition>
    <transition name="fade" mode="out-in">
      <VerticalLegends
        :isSettingsExpanded="isSettingsExpanded"
        :key="'map_legend'"
        v-if="mapToggleSettings.showLegends && selectedHexOption && selectedHexOption.id !== 'none'"
      >
        <div v-if="maxLegend && !isLoading" class="box">
          <p>{{ $tc('map.max') }} {{ maxLegend }}</p>
        </div>
        <MapLegend
          v-if="!isLoading"
          class="box"
          :key="selectedHexOption.id"
          :h3WithMinValue="h3WithMinValue"
          :h3WithMaxValue="h3WithMaxValue"
          :H3AssetDataMapped="H3AssetDataMapped"
          :h3HexColorsPalette="h3HexColorsPalette"
          :selectedOverlayUnitUI="selectedOverlayUnitUI"
          @legendChange="h3dataFiltered"
        />
      </VerticalLegends>
    </transition>
    <TooltipStyled id="tooltip" :isVisible="tooltipHex && tooltipHex.h3hex">
      <div>ID: {{ tooltipHex.h3hex }}</div>
      <div>{{ tooltipHex.name }}: {{ tooltipHex.value }}</div>
    </TooltipStyled>
    <TooltipStyled id="tooltip-geofence" :isVisible="tooltipGeofence && tooltipGeofence.name">
      <div>Name: {{ tooltipGeofence.name }}</div>
      <div>Material Type: {{ tooltipGeofence.materialType }}</div>
      <div>Geofence Type: {{ tooltipGeofence.geofenceType }}</div>
    </TooltipStyled>
    <div class="assets">
      <TalpaIcon
        v-for="asset in assetsWithIcons"
        class="marker"
        :key="asset.id"
        :ref="'marker_' + asset.id"
        :scope="'AssetTypes'"
        :name="asset.icon"
        :isMarker="true"
      />
    </div>
  </MapStyled>
</template>

<script>
import mapboxgl, { LngLat, LngLatBounds } from 'mapbox-gl'
import chroma from 'chroma-js'
import { styled } from '@egoist/vue-emotion'
import { debounce } from 'vue-debounce'
import { parse } from 'wellknown'

import { ScatterplotLayer, GeoJsonLayer } from '@deck.gl/layers'
import { H3HexagonLayer } from '@deck.gl/geo-layers'
import { MapboxLayer } from '@deck.gl/mapbox'
import 'mapbox-gl/dist/mapbox-gl.css'

import units from '@/utils/units'

import { TalpaIcon } from '@common/components'

import { flexCenter } from '@styles/mixins'
import resizeMixin from '@/mixins/resize'
import permissionsMixin from '@/mixins/permissions'
import { GeofenceColors } from '@styles/themes'
import { TimeframeMixin } from '@common/mixins'
import MapLegend from '@/components/Map/Main/Legend/MapLegend'
import { LoadingDots, CloseButtonAtom } from '@common/components'

import { useAssetStore } from '@/stores/assets'

import TALPALYTICS_GEOFENCES_QUERY from '#/graphql/misc/talpalyticsGeofences.gql'
import H3_ASSET_DATA_QUERY from '#/graphql/misc/h3AssetData.gql'

const MapStyled = styled('div')`
  ${flexCenter}
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .map-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .mapboxgl-popup-content {
    color: ${p => p.theme.colors.darkGrey};
  }
  .mapboxgl-marker {
    z-index: 0 !important;
  }
  .assets {
    display: none;
  }

  .mapboxgl-popup {
    z-index: 1000;
  }
`

const TooltipStyled = styled('div')`
  ${flexCenter}
  flex-direction: column;
  align-items: stretch;
  position: absolute;
  padding: 0.5rem;
  z-index: 1;
  pointer-events: none;
  color: ${props => props.theme.colors.talpaGreyDarker};
  background: ${props => chroma(props.theme.colors.white).alpha(0.5).css()};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.5s ease 0s;
`
const MessageBox = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
  position: absolute;
  bottom: 0;
  padding: 1rem;
  width: 30rem;
  background: ${({ theme }) => chroma(theme.colors.selectBG).alpha(0.8).css()};
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  transition: opacity 0.5s ease 0s;
  text-align: center;
  .message {
    display: flex;
    justify-content: center;
    align-items: baseline;
    .dots {
      margin: 0 0 0 0.25rem;
    }
  }
  .close-button {
    display: grid;
    align-items: center;
  }
`

const VerticalLegends = styled('div')`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 5rem;
  margin-left: ${props => (props.isSettingsExpanded ? '14rem' : '3rem')};
  width: fit-content;
  min-width: 4rem;
  max-width: 6rem;
  .box {
    text-align: center;
    margin-bottom: 10px;
    background: ${({ theme }) => chroma(theme.colors.selectBG).alpha(0.8).css()};
    height: 100%;
    border-radius: 0.5rem;
    font-size: 12px;

    .row {
      margin: 10px;
      display: flex;
      flex-direction: row;
    }
    p {
      padding: 10px;
    }
  }
  @media (max-width: 768px) {
    top: 10rem;
  }
`
const ZOOM_LIMIT = 13
export default {
  inject: ['theme'],
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  mixins: [resizeMixin, permissionsMixin, TimeframeMixin],
  props: {
    sites: {
      type: Array,
    },
    mapToggleSettings: {
      type: Object,
      required: true,
    },
    selectedHexOption: {
      type: Object,
      required: false,
    },
    selectedActivities: {
      type: Array,
      required: false,
    },
    isSettingsExpanded: {
      type: Boolean,
      default: false,
    },
    tileSets: {
      type: Array,
    },
    assets: {
      type: Array,
      required: true,
    },
  },
  components: {
    MapStyled,
    TalpaIcon,
    TooltipStyled,
    VerticalLegends,
    MapLegend,
    MessageBox,
    LoadingDots,
    CloseButtonAtom,
  },
  data() {
    const { h3hexRange } = this.theme.colors.map

    return {
      h3HexColorsPalette: h3hexRange,
      h3AssetData: [],
      map: null,
      showActions: false,
      renderedChildren: false,
      renderedContainer: false,
      h3layer: null,
      mapReady: false,
      tooltipHex: {},
      tooltipGeofence: {},
      assetMarkers: [],
      scatterInitialized: false,
      orthoEnabled: false,
      showLegends: false,
      geojson: {},
      geojsonLayer: null,
      H3AssetDataMapped: [],
      h3WithMaxValue: null,
      h3WithMinValue: null,
      accessToken: 'pk.eyJ1IjoidGFscGEtdGVhbSIsImEiOiJjamxyd2lqZGswOWFvM3FrOGpsczZzcnpzIn0.0FGUVq9gCmYCluoyFxVykw',
      currentZoom: 0,
      assetsInView: new Set(),
      message: null,
    }
  },
  computed: {
    nonNoneHexOptionId() {
      if (this.selectedHexOption?.id && this.selectedHexOption?.id !== 'none') {
        return this.selectedHexOption?.id
      } else {
        return null
      }
    },
    showLoadingDots() {
      return this.$apollo.queries.h3AssetData.loading || this.$apollo.queries.geofences.loading
    },
    assetIdsinView() {
      return Array.from(this.assetsInView)
    },
    showGeofences() {
      return !!this.mapToggleSettings?.showGeofences
    },
    selectedGeofence() {
      return this.mapToggleSettings?.selectedGeofence
    },
    useStreetsStyle() {
      return !!this.mapToggleSettings?.useStreetsStyle
    },
    selectedOverlayUnitUI() {
      const unitUI =
        this.selectedUIUnitSystem === 'IMPERIAL'
          ? this.selectedHexOption.physicalUnitUIImperial
          : this.selectedHexOption.physicalUnitUIMetric
      return unitUI
    },
    maxLegend() {
      if (!this.h3WithMaxValue) {
        return
      }
      return `${this.h3WithMaxValue} ${this.selectedOverlayUnitUI}`
    },
    assetsCenter() {
      const assetPositions = this.assetPositions
      if (assetPositions.length < 1) {
        return {
          lat: 0,
          lng: 0,
          zoom: 1.5,
          pitch: 30,
          bearing: 0,
        }
      }
      if (assetPositions.length === 1) {
        return {
          lat: assetPositions[0].lat,
          lng: assetPositions[0].lng,
          zoom: 17,
          pitch: 30,
          bearing: 0,
        }
      }
      const bounds = this.assetBounds
      const { lat, lng } = bounds.getCenter()
      return {
        lat,
        lng,
        zoom: 12,
        pitch: 30,
        bearing: 0,
        bounds: bounds,
      }
    },
    assetBounds() {
      const assetPositions = this.assetPositions

      if (assetPositions.length < 2) {
        return null
      }
      const lats = assetPositions.map(pos => pos.lat)
      const lngs = assetPositions.map(pos => pos.lng)

      const west = Math.min(...lngs)
      const east = Math.max(...lngs)
      const south = Math.min(...lats)
      const north = Math.max(...lats)
      const sw = new LngLat(west, south)
      const ne = new LngLat(east, north)
      return new LngLatBounds(sw, ne)
    },
    selectedAssets() {
      return this.assets
    },
    assetsWithPosition() {
      const assets = this.selectedAssets ?? []
      const assetsWithPosition = assets.filter(asset => (asset?.lastPosition?.lat ?? null) !== null)
      return assetsWithPosition
    },
    assetPositions() {
      const positions = this.assetsWithPosition?.map(({ lastPosition }) => lastPosition)
      return positions.map(({ lat, lng }) => ({ lat, lng }))
    },
    isLoading() {
      return this.$apollo.loading
    },
    assetsWithIcons() {
      const assetsWithIcons = this.assetsWithPosition?.map(asset => ({
        ...asset,
        icon: (asset?.type?.name ?? '').split(' ').join(''),
      }))
      return assetsWithIcons
    },
    assetsGeoJSON() {
      const geojson = {
        type: 'FeatureCollection',
        features: this.assetsWithPosition?.map(asset => {
          const parsed = {
            type: 'Point',
            coordinates: [asset?.lastPosition?.lng ?? null, asset?.lastPosition?.lat ?? null],
          }
          return {
            id: asset?.id ?? 'unknown',
            type: 'Feature',
            properties: {
              ...asset,
              icon: (asset?.type?.name ?? '').split(' ').join(''),
            },
            geometry: parsed,
          }
        }),
      }
      return geojson
    },
    readyToInit() {
      return this.renderedChildren && this.assetsCenter
    },
  },
  created() {
    window.addEventListener('resize', this.handleWindowResize)
  },
  mounted() {
    this.renderedContainer = true
    this.$nextTick(() => {
      this.renderedChildren = true
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize)
    this.cleanup()
    this.renderedChildren = false
    this.renderedContainer = false
  },
  watch: {
    nonNoneHexOptionId: {
      handler(value) {
        if (value && this.currentZoom < ZOOM_LIMIT) {
          this.message = 'map.messages.zoomInForHeatMap'
        } else {
          this.message = this.message === 'map.messages.zoomInForHeatMap' ? null : this.message
        }
      },
    },
    useStreetsStyle: {
      handler(useStreetsStyle) {
        this.map?.setStyle(useStreetsStyle ? 'mapbox://styles/mapbox/streets-v12' : 'mapbox://styles/mapbox/satellite-v9')
        this.destroyAndReinit()
      },
    },
    showGeofences: {
      handler(show) {
        if (!show) {
          if (this.geojsonLayer && this.map) {
            const id = this.geojsonLayer?.id
            const layer = this.map.getLayer(id)
            if (layer) {
              this.map.removeLayer(id)
            }
          }
        }
      },
    },
    selectedAssets: {
      handler() {
        this.assetsInView = new Set()
        if (this.map && this.renderedChildren) {
          this.map.getSource('assets_source')?.setData(this.assetsGeoJSON)
          if (this.map) {
            this.moveMap()
          }
          this.updateMarkers()
          if (this.assetsWithPosition?.length == 0) {
            this.message = 'map.messages.allMachinesNoLocation'
          }
          if (this.assetsWithPosition?.length < this.selectedAssets.length && this.message === null) {
            this.message = 'map.messages.someMachinesNoLocation'
          }
        }
      },
      deep: true,
      immediate: true,
    },
    selectedUIUnitSystem: {
      handler() {
        if (this.h3AssetData && this.selectedHexOption?.id) {
          this.sortH3AssetData()
        }
      },
    },
    readyToInit: {
      handler() {
        if (this.renderedChildren) {
          if (!this.map) {
            this.initMap()
          }
        }
      },
      immediate: true,
    },

    selectedHexOption: {
      handler() {
        if (this.h3layer) {
          const visible = this.selectedHexOption.id !== 'none'
          this.h3layer.setProps({
            visible,
          })
        }
      },
      immediate: true,
    },
    selectedActivities: debounce(function () {
      this.$apollo.queries.h3AssetData.refetch()
    }, 500),
  },
  methods: {
    stop() {
      this.$apollo.queries.h3AssetData.stop()
    },
    closeMessageBox() {
      this.message = null
    },
    handleWindowResize: debounce(function () {
      this.destroyAndReinit()
    }, 300),
    moveMap() {
      const center = [this.assetsCenter?.lng ?? 0, this.assetsCenter?.lat ?? 0]
      if (this.map) {
        if (this.assetBounds) {
          this.map.fitBounds(this.assetBounds, {
            linear: false,
            padding: 40,
          })
        } else if (this.assetsCenter) {
          this.map.flyTo({
            center,
            speed: 2.2,
            zoom: this.assetsCenter.zoom,
          })
        }
      }
    },
    gridItemResized() {
      if (this.map) {
        this.$nextTick(() => {
          this.destroyAndReinit()
        })
      }
    },
    destroyAndReinit() {
      this.cleanup()
      this.initMap()
    },
    cleanup() {
      if (this.scatterLayer?.id && this.map.getLayer(this.scatterLayer.id)) {
        this.map.removeLayer(this.scatterLayer.id)
      }
      if (this.h3layer?.id && this.map.getLayer(this.h3layer.id)) {
        this.map.removeLayer(this.h3layer.id)
      }
      if (this.map) {
        this.map.remove()
      }

      this.assetMarkers.forEach(({ marker }) => {
        marker.remove()
      })
      this.assetMarkers = []
      this.mapReady = false
      this.map = null
    },
    toggleShowActions() {
      this.showActions = !this.showActions
    },
    getIcon(assetTypeName) {
      return assetTypeName.split(' ').join('')
    },
    legendToggle() {
      this.showLegends = !this.showLegends
      this.renderH3Layer([this.h3WithMinValue, this.h3WithMaxValue])
    },
    initMap() {
      if (this.map) {
        throw new Error('Map already initialized?')
      }
      this.map = null
      const center = [this.assetsCenter?.lng ?? 0, this.assetsCenter?.lat ?? 0]
      this.map = new mapboxgl.Map({
        accessToken: this.accessToken,
        container: this.$refs.map,
        style: this.mapToggleSettings.useStreetsStyle ? 'mapbox://styles/mapbox/streets-v12' : 'mapbox://styles/mapbox/satellite-v9',
        center,
        zoom: this.assetsCenter?.zoom ?? 12,
        bearing: this.assetsCenter?.bearing ?? 0,
        pitch: this.assetsCenter?.pitch ?? 30,
      })
      if (this.assetBounds) {
        this.map.fitBounds(this.assetBounds, {
          linear: false,
          padding: 100,
        })
      }
      this.map.on('load', () => {
        this.renderScatterLayer()
        this.$nextTick(() => {
          this.mapReady = true
        })
        /**
         * Changes for the Mapbox Tiling Service
         */
        if (this.tileSets.length && !this.useStreetsStyle) {
          this.renderTileLayer()
        }
        this.map.addSource('assets_source', {
          type: 'geojson',
          data: this.assetsGeoJSON,
          cluster: true,
          clusterRadius: 20,
        })
        this.map.addLayer({
          id: 'assets_cluster',
          type: 'circle',
          source: 'assets_source',
          filter: ['has', 'point_count'],
          paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 5
            //   * Yellow, 30px circles when point count is between 5 and 10
            //   * Pink, 40px circles when point count is greater than or equal to 10
            'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 5, '#f1f075', 10, '#f28cb1'],
            'circle-radius': ['step', ['get', 'point_count'], 20, 5, 30, 10, 40],
          },
        })
        this.map.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'assets_source',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12,
          },
        })
        this.map.addLayer({
          id: 'unclustered-asset',
          type: 'circle',
          source: 'assets_source',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#11b4da',
            'circle-radius': 4,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff',
          },
        })

        if (this.orthoEnabled) {
          this.map.addSource('halbeswig_2018_11', {
            type: 'raster',
            tiles: [
              'http://localhost:9090/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=talpa:halbeswig_2018_11&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=image/png&TILECOL={x}&TILEROW={y}',
            ],
            tileSize: 256,
            minZoom: 18,
            maxZoom: 31,
            bounds: [8.387197234670383, 51.321461889905535, 8.398382079972368, 51.33018923444928],
          })
          this.map.addLayer({
            id: 'halbeswig_2018_11',
            type: 'raster',
            source: 'halbeswig_2018_11',
            layout: {
              visibility: 'none',
            },
          })
          this.map.addSource('halbeswig_2019_11', {
            type: 'raster',
            tiles: [
              'http://localhost:9090/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=talpa:halbeswig_2019_11&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=image/png&TILECOL={x}&TILEROW={y}',
            ],
            tileSize: 256,
            minZoom: 18,
            maxZoom: 31,
            bounds: [8.387197234670383, 51.321461889905535, 8.398382079972368, 51.33018923444928],
          })
          this.map.addLayer({
            id: 'halbeswig_2019_11',
            type: 'raster',
            source: 'halbeswig_2019_11',
            layout: {
              visibility: 'none',
            },
          })
        }
      })
      this.map.on('move', this.updateMarkers)
      this.map.on('moveend', this.onMapMoveEnded)
      this.map.on('zoomend', () => {
        this.currentZoom = this.map.getZoom()
        if (this.currentZoom <= ZOOM_LIMIT - 3 && this.h3layer?.id && this.map.getLayer(this.h3layer.id)) {
          this.map.removeLayer(this.h3layer.id)
        }
        if (this.assetsWithPosition?.length == 0) {
          this.message = 'map.messages.allMachinesNoLocation'
        } else if (this.nonNoneHexOptionId && this.currentZoom < ZOOM_LIMIT) {
          this.message = 'map.messages.zoomInForHeatMap'
        }
      })
    },
    /**
     * onMapMoveEnded executes on every moveend event.
     * on each move end events onMapMoveEnded will call updateMarkers method.
     * Then it will also fetch all the rendered features from the view and iterate through them.
     * If a feature is a cluster all the leave nodes features are retreived using getClusterLeaves method.
     * prop id of each of these features are the assetids.
     * If the user is zoomed in more than the limit new asset ids are appended to the set
     * else the set will be recreated with asset ids got from the rendered features.
     */
    async onMapMoveEnded() {
      if (!this.map) {
        return
      }
      this.updateMarkers()
      if (this.currentZoom < ZOOM_LIMIT) {
        return
      }
      const features = this.map.queryRenderedFeatures()
      const assetIds = []
      for await (const feature of features) {
        const props = feature.properties
        if (props.cluster) {
          const features = await this.getClusterLeaves(props.cluster_id, props.point_count, 0)
          const ids = features.map(f => f.id)
          assetIds.push(...ids)
        } else {
          if (props.id) {
            assetIds.push(props.id)
          }
        }
      }
      if (this.currentZoom < ZOOM_LIMIT) {
        this.assetsInView = new Set(assetIds)
      } else {
        this.assetsInView = new Set([...assetIds, ...Array.from(this.assetsInView)])
      }
    },
    async getClusterLeaves(cluster_id, limit, offset) {
      return new Promise((resolve, reject) => {
        this.map.getSource('assets_source').getClusterLeaves(cluster_id, limit, offset, (err, features) => {
          if (err) reject(err)
          else resolve(features)
        })
      })
    },
    updateMarkers() {
      if (!this.map) {
        return
      }
      this.assetMarkers.forEach(({ marker }) => {
        marker.remove()
      })
      if (this.assetsWithPosition?.length == 0) {
        return
      }
      const features = this.map.querySourceFeatures('assets_source')
      features.forEach(feature => {
        const coordinates = feature.geometry.coordinates
        const props = feature.properties
        if (props.cluster) {
          return
        }
        let marker = this.assetMarkers.find(af => af.props.id === props.id)
        if (!marker) {
          marker = this.createMarker(props)
          marker.setLngLat(coordinates)
          marker.addTo(this.map)
          this.assetMarkers.push({
            props,
            marker,
          })
        } else {
          marker.marker.setLngLat(coordinates)
          marker.marker.addTo(this.map)
        }
      })
    },
    createMarker(asset) {
      const popup = new mapboxgl.Popup({
        offset: 35,
      }).setText(asset.name)
      const id = 'marker_' + asset.id
      const ref = this.$refs[id]?.[0]
      if (!ref) {
        return
      }
      const el = ref.$el.cloneNode(true)
      const marker = new mapboxgl.Marker({
        element: el,
        anchor: 'bottom',
      }).setPopup(popup)
      return marker
    },
    renderScatterLayer() {
      const center = [this.assetsCenter?.lng ?? 0, this.assetsCenter?.lat ?? 0]
      this.scatterLayer = new MapboxLayer({
        id: 'my-scatterplot',
        type: ScatterplotLayer,
        data: [{ position: [center.lng, center.lat], size: 100 }],
        getPosition: d => d.position,
        getRadius: d => d.size,
        getFillColor: [255, 0, 0, 0],
      })
      this.map.addLayer(this.scatterLayer)
      this.scatterInitialized = true
    },
    setTooltip(object, x, y) {
      const el = document.getElementById('tooltip')
      const unitUI =
        this.selectedUIUnitSystem === 'IMPERIAL'
          ? this.selectedHexOption.physicalUnitUIImperial
          : this.selectedHexOption.physicalUnitUIMetric
      if (object) {
        this.tooltipHex = {
          ...object,
          name: this.selectedHexOption.label,
          value: object.count + unitUI,
        }
        el.style.display = 'block'
        el.style.left = x + 'px'
        el.style.top = y - 100 + 'px'
      } else {
        el.style.display = 'none'
      }
    },
    setTooltipGeofence({ object, x, y }) {
      const el = document.getElementById('tooltip-geofence')
      if (!el || !object) {
        if (el) {
          el.style.display = 'none'
        }
        return
      }
      if (object.properties) {
        this.tooltipGeofence = {
          ...object.properties,
        }
        el.style.display = 'block'
        el.style.left = x + 'px'
        el.style.top = y - 100 + 'px'
      } else {
        el.style.display = 'none'
      }
    },
    sortH3AssetData() {
      const h3AssetData = this.h3AssetData
      const unitConvertedH3Data = h3AssetData.map(d => {
        return {
          ...d,
          count: units(d.count, this.selectedHexOption.physicalUnitSI, this.selectedOverlayUnitUI, 2, false),
        }
      })
      this.h3WithMaxValue = unitConvertedH3Data.reduce((acc, item) => {
        return Math.max(item.count, acc)
      }, 0)
      this.h3WithMinValue = unitConvertedH3Data.reduce((acc, item) => {
        return Math.min(item.count, acc)
      }, 0)
      const colors = chroma.scale(this.h3HexColorsPalette)

      this.H3AssetDataMapped = unitConvertedH3Data.map(d => {
        const relativeValue = (d.count - this.h3WithMinValue) / this.h3WithMaxValue
        return {
          ...d,
          color: colors(relativeValue).rgb(),
        }
      })
      this.renderH3Layer([this.h3WithMinValue, this.h3WithMaxValue])
    },
    h3dataFiltered(filteredRange) {
      this.renderH3Layer(filteredRange)
    },
    renderH3Layer(filteredRange) {
      const data = this.H3AssetDataMapped.filter(d => d.count >= filteredRange[0] && d.count <= filteredRange[1])
      const id = this.h3layer?.id
      this.removeLayer(id)
      const elevationScaleRatio = this.h3WithMaxValue === 0 ? 0.05 : 1 / this.h3WithMaxValue
      this.h3layer = new MapboxLayer({
        id: 'h3hex-layer',
        type: H3HexagonLayer,
        data,
        pickable: true,
        filled: true,
        stroked: true,
        extruded: true,
        elevationScale: elevationScaleRatio * 20,
        getLineColor: [0, 0, 0, 20],
        getHexagon: d => d.h3hex,
        getStrokeColor: [0, 0, 0, 124],
        getFillColor: d => d.color,
        getElevation: d => d.count,
        opacity: 0.6,
        onHover: info => this.setTooltip(info.object, info.x, info.y),
        visible: !!this.selectedHexOption?.id,
      })
      this.map.addLayer(this.h3layer)
    },
    renderGeojsonLayer() {
      const data = this.geojson
      const id = this.geojsonLayer?.id
      this.removeLayer(id)

      this.geojsonLayer = new MapboxLayer({
        id: 'geojson-layer',
        type: GeoJsonLayer,
        data,
        pickable: true,
        filled: true,
        stroked: true,
        getLineColor: [0, 0, 0, 20],
        lineWidthMinPixels: 1,
        getStrokeColor: [0, 0, 0, 124],
        getFillColor: d => GeofenceColors[d.properties.materialType] || GeofenceColors['UNKNOWN'],
        onHover: this.setTooltipGeofence,
        transitions: {
          getLineColor: 1000,
          getLineWidth: 1000,
        },
      })
      this.map.addLayer(this.geojsonLayer)
    },
    renderTileLayer() {
      this.tileSets.forEach(site => {
        this.map.addSource(site.name, {
          type: 'raster',
          url: `mapbox://${site.tileSetId}`,
        })
        this.map.addLayer({
          id: site.tileSetId,
          type: 'raster',
          source: site.name,
        })
      })
    },
    removeLayer(id) {
      if (id) {
        const layer = this.map.getLayer(id)
        if (layer) {
          this.map.removeLayer(id)
        }
      }
    },
  },
  apollo: {
    geofences: {
      query: TALPALYTICS_GEOFENCES_QUERY,
      variables() {
        return {
          siteNames: this.sites,
        }
      },
      skip() {
        return !this.showGeofences || !this.mapReady || this.sites.length < 1
      },
      manual: true,
      result({ data }) {
        if (data.talpalyticsGeofences) {
          const geojson = {
            type: 'FeatureCollection',
            features: (data?.talpalyticsGeofences ?? []).map(geofence => {
              const parsed = parse(geofence?.polygon)
              return {
                type: 'Feature',
                properties: {
                  name: geofence?.name ?? 'unknown',
                  materialType: geofence?.materialType ?? 'unknown',
                  geofenceType: geofence?.geofenceType ?? 'unknown',
                },
                geometry: parsed,
              }
            }),
          }
          this.geojson = geojson
          if (this.map) {
            this.renderGeojsonLayer()
          }
        } else {
          this.geojson = {}
        }
      },
      watchLoading(isLoading) {
        if (isLoading) {
          this.message = 'map.messages.generatingGeofences'
        } else {
          this.message = null
        }
      },
    },
    h3AssetData: {
      query: H3_ASSET_DATA_QUERY,
      skip() {
        return (
          !this.mapReady ||
          !this.selectedHexOption?.id ||
          !this.selectedHexOption?.name ||
          !this.scatterInitialized ||
          this.assetIdsinView.length < 1 ||
          !this.selectedTimeframeParam?.end ||
          this.currentZoom < ZOOM_LIMIT
        )
      },
      manual: true,
      variables() {
        const activity = this.selectedActivities.length > 0 ? this.selectedActivities.map(({ id }) => id) : []

        return {
          kpi: this.selectedHexOption.name,
          timeframe: this.selectedTimeframeParam,
          assets: {
            id_in: this.assetIdsinView,
          },
          activity,
          unknownGeofenceFilter: this.selectedGeofence?.id,
        }
      },
      result({ data }) {
        const h3Data = data?.h3AssetData ?? []
        if (h3Data.length > 0) {
          this.h3AssetData = h3Data
          this.sortH3AssetData()
        } else {
          this.message = this.message = 'map.messages.selectedMachinesNoHeatMap'
          this.removeLayer(this.h3layer?.id)
        }
      },
      watchLoading(isLoading) {
        if (isLoading) {
          this.message = 'map.messages.generatingHeatMap'
        } else {
          this.message = null
        }
      },
    },
  },
}
</script>
