<template>
  <HeatMapSettingsStyled>
    <span class="subheader"> {{ $tc('dimension', 1) | capitalize }}: </span>
    <BasicSelect
      class="select"
      :key="'select_kpi'"
      :options="availableAssetDimensions"
      :selected="selectedKPI"
      @change="setSelectedKpiOption"
    />
    <span class="subheader"> {{ $t('activity') | capitalize }}: </span>

    <SimplePopover :disabled="!isDisabled" :popoverContent="$t('map.info')" class="wrapper">
      <template v-slot:trigger>
        <multiselect
          :placeholder="$t('selects.activities') | capitalize"
          :value="selectedActivities"
          :select-label="''"
          :selected-label="''"
          :deselect-label="''"
          :options="selectableActivities"
          :multiple="true"
          track-by="id"
          :hide-selected="false"
          :custom-label="customLabel"
          :close-on-select="false"
          :disabled="isDisabled"
          :taggable="false"
          @select="setSelectedActivityOption($event)"
          @remove="setSelectedActivityOption($event)"
          class="activity-multi-select"
          open-direction="bottom"
          :class="{ disabled: isDisabled }"
        >
          <span class="checkbox-label" slot="option" slot-scope="scope">
            <input class="align" type="checkbox" id="checkbox" v-model="scope.option.checked" @focus.prevent />
            <span class="checkmark"></span>
            {{ scope.option.label }}
          </span>
          <ChevronDownIcon slot-scope="{ toggle }" @mousedown.prevent.stop="toggle" class="multiselect__select caretIcon" slot="caret" />
        </multiselect>
      </template>
      <template v-slot:icon>
        <AlertTriangleIcon size="1.2x" />
      </template>
    </SimplePopover>
    <div v-if="selectedActivities.length">
      <span class="subheader"> {{ $tc('showUnknowns', 1) | capitalize }}: </span>
      <BasicSelect
        class="wrapper"
        :key="'select_geofence'"
        :options="cycleGeofenceData"
        :selected="selectedGeofence"
        @change="setSelectedGeofenceOption"
      />
    </div>
  </HeatMapSettingsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { BasicSelect } from '@common/components'
import Multiselect from 'vue-multiselect'
import get from 'lodash/get'
import { AlertTriangleIcon, ChevronDownIcon } from 'vue-feather-icons'
import SimplePopover from '@common/components/src/SimplePopover'
import { TimeframeMixin } from '@common/mixins'
import localesMixin from '@/mixins/locales'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

import AVAILABLE_ACTIVITIES from '#/graphql/assetDimensions/availableActivities.gql'
import ASSET_DIMENSIONS_QUERY from '#/graphql/assetDimensions/assetDimensions.gql'

const HeatMapSettingsStyled = styled('div')`
  grid-area: overlayer;
  margin-bottom: 2.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${p => p.theme.colors.subheaderInactiveBG};

  .select {
    margin: 0.5rem 0 1rem 0;
  }

  .open {
    max-height: 125px;
    overflow: auto;
  }
  > .icon {
    width: 16px;
    height: 16px;
    stroke: ${({ theme }) => theme.colors.shuttleGrey};
    cursor: pointer;
    &:hover {
      stroke: ${p => p.theme.colors.primaryLighter};
    }
  }
  .warning {
    display: flex;
    justify-content: space-between;
  }

  .wrapper {
    margin: 1rem 0;
  }

  .multiselect {
    border: none;

    &:hover {
      border-bottom: none;
    }
    .caretIcon {
      stroke: ${({ theme }) => theme.colors.primaryDarker};
    }
    .checkbox-label {
      display: flex;
      cursor: pointer;
    }

    .checkbox-label input {
      margin-left: 1rem;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 13px;
      left: 4px;
      height: 16px;
      width: 16px;
      background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    .checkbox-label:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .checkbox-label input:checked ~ .checkmark {
      background-color: ${p => p.theme.colors.primaryLighter};
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .checkbox-label input:checked ~ .checkmark:after {
      display: flex;
    }

    /* Style the checkmark/indicator */
    .checkbox-label .checkmark:after {
      left: 4px;
      top: -1px;
      width: 5px;
      height: 11px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .disabled {
    .multiselect--disabled,
    .multiselect__tags {
      background-color: ${({ theme }) => theme.colors.disabled} !important;
    }
  }
  .activity-multi-select {
    width: 190px;
    .multiselect--active .multiselect__placeholder {
      display: none;
      color: ${p => p.theme.colors.selectBG};
      background-color: ${p => p.theme.colors.primaryDarker};
    }
    .multiselect__single,
    .multiselect__input,
    .multiselect__placeholder,
    .multiselect__content-wrapper {
      font-size: 13px;
      color: ${p => p.theme.colors.normalFontColor};
      max-height: 200px;
    }

    .multiselect__input,
    .multiselect__placeholder {
      padding-left: 9px;
    }

    .multiselect__tags,
    .multiselect__single {
      padding: 8px 28px 0 8px;
      appearance: none;
      max-height: 5rem;
      overflow: auto;
      box-shadow: ${p => p.theme.colors.widgetShadow};
      color: ${p => p.theme.colors.normalFontColor};
      .multiselect__tag {
        color: ${({ theme }) => (theme.isDark ? theme.colors.white : theme.colors.darkGrey)};
        margin-right: 0;
      }
    }
    .multiselect__option--highlight {
      background-color: ${p => p.theme.colors.primaryDarker};
    }
  }
`
export default {
  mixins: [TimeframeMixin, localesMixin],
  props: {
    localSelectedSettings: {
      type: Object,
    },
    assetIds: {
      type: Array,
      required: true,
    },
  },
  components: {
    HeatMapSettingsStyled,
    BasicSelect,
    ChevronDownIcon,
    Multiselect,
    SimplePopover,
    AlertTriangleIcon,
  },

  data() {
    return {
      selectedActivities: [],
      availableActivities: [],
      assetDimensions: [],
      selectedKPI: '',
      selectedGeofence: '',
    }
  },
  computed: {
    locale() {
      return (this.uiSettings?.dates ?? 'DE_DE').replace('_', '-').slice(0, 2)
    },
    isDisabled() {
      return get(this.selectedKPI, 'id', '') === 'none'
    },
    availableAssetDimensions() {
      const data = this.assetDimensions
        .filter(dim => dim.hasHeatmap)
        .map(d => {
          return {
            ...d,
            label: d?.nameTranslations
              ? getAssetDimensionNameByLocale(d?.nameTranslations, this.locale).replace(/^./, str => str.toUpperCase())
              : d?.name,
          }
        })
      data.push({
        id: 'none',
        label: this.$t('none'),
      })
      return data
    },
    selectableActivities() {
      const val = this.availableActivities.map(a => {
        let checked = false
        if (this.localSelectedSettings && this.localSelectedSettings.selectedActivityList.length > 0) {
          const found = this.localSelectedSettings.selectedActivityList.find(s => s.id === a.activityType)
          if (found) {
            checked = true
          }
        }
        return {
          id: a.activityType,
          label: this.$t('activityTypes.' + a.activityType).replace(/^./, str => str.toUpperCase()),
          checked,
        }
      })
      return val
    },
    cycleGeofenceData() {
      return [
        { id: 'none', label: this.$t('noFilter') },
        { id: 'source', label: this.$t('cycleTable.sourceGeofence') },
        { id: 'target', label: this.$t('cycleTable.targetGeofence') },
      ]
    },
  },
  methods: {
    customLabel(option) {
      return `${option.label}`
    },
    setSelectedKpiOption(o) {
      this.selectedKPI = o
      if (this.isDisabled) {
        this.selectedActivities = []
      }
      this.$emit('selectedActivities', this.selectedKPI, this.selectedActivities)
    },
    setSelectedActivityOption(o) {
      const isActivitySelected = !!this.selectedActivities.find(({ id }) => id === o.id)
      if (isActivitySelected) {
        o.checked = false
        this.selectedActivities = this.selectedActivities.filter(({ id }) => id !== o.id)
      } else {
        o.checked = true
        this.selectedActivities.push(o)
      }
      this.$emit('selectedActivities', this.selectedKPI, this.selectedActivities)
    },
    setSelectedGeofenceOption(option) {
      this.selectedGeofence = option
      this.$emit('setToggleValues', 'selectedGeofence', this.selectedGeofence)
    },
  },
  watch: {
    localSelectedSettings: {
      handler() {
        this.selectedKPI = this.localSelectedSettings.selectedDimension
        this.selectedActivities = this.localSelectedSettings.selectedActivityList
        this.selectedGeofence = this.cycleGeofenceData.find(f => f.id === this.localSelectedSettings.selectedGeofence?.id)
      },
      immediate: true,
    },
  },
  apollo: {
    assetDimensions: {
      query: ASSET_DIMENSIONS_QUERY,
      variables() {
        return {
          where: {},
        }
      },
    },
    availableActivities: {
      query: AVAILABLE_ACTIVITIES,
      variables() {
        return {
          where: {
            assets: {
              id_in: this.assetIds,
            },
            timeframe: this.selectedTimeframeParam,
          },
        }
      },
      skip() {
        return this.assetIds.length < 1 || !this.selectedTimeframe
      },
    },
  },
}
</script>
