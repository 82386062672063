var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MainStyled', {
    attrs: {
      "isSettingsExpanded": _vm.isSettingsExpanded
    }
  }, [_vm.isLoading ? _c('TalpaLoaderWrapper') : [_c('Subheader', {
    staticClass: "subheader",
    attrs: {
      "hasScrolled": _vm.hasScrolled
    }
  }), _c('transition', {
    attrs: {
      "name": "fade-in-left"
    }
  }, [_vm.displayOverlay ? _c('MapSettings', {
    attrs: {
      "localSelectedSettings": _vm.localSelectedSettings,
      "assetIds": _vm.assetIds
    },
    on: {
      "toggleSettingsExpanded": _vm.toggleSettingsExpanded,
      "toggleSettings": _vm.toggleSettings,
      "selectedHeatmapData": _vm.activitiesList
    }
  }) : _vm._e()], 1), _c('MapStyled', [_c('MapOrganism', {
    attrs: {
      "sites": _vm.sites,
      "mapToggleSettings": _vm.localSelectedSettings,
      "isSettingsExpanded": _vm.isSettingsExpanded,
      "selectedActivities": _vm.selectedActivities,
      "selectedHexOption": _vm.selectedKPI,
      "tileSets": _vm.tileSets,
      "assets": _vm.assetsSelected
    }
  })], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }