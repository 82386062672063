var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MapSettingsStyled', {
    attrs: {
      "isSettingsExpanded": _vm.isSettingsExpanded
    }
  }, [_c('div', {
    staticClass: "settings"
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_c('SettingsExpandToggleStyled', {
    key: 'icon',
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleSettingsExpanded();
      }
    }
  }, [_vm.isSettingsExpanded ? _c('ChevronLeftIcon') : _c('LayersIcon')], 1)], 1), _c('transition-group', {
    attrs: {
      "name": "fade-in-left"
    }
  }, [_vm.isSettingsExpanded ? _c('div', {
    key: 'header',
    staticClass: "header"
  }, [_vm._v(_vm._s(_vm.$t('map.heatmap')))]) : _vm._e(), _vm.isSettingsExpanded ? _c('SettingsDetails', {
    key: 'settings'
  }, [_c('HeatMapSettings', {
    attrs: {
      "localSelectedSettings": _vm.localSelectedSettings,
      "assetIds": _vm.assetIds
    },
    on: {
      "selectedActivities": _vm.heatMapData,
      "setToggleValues": _vm.setValue
    }
  }), _c('MapSettingToggles', {
    attrs: {
      "localSelectedSettings": _vm.localSelectedSettings
    },
    on: {
      "setToggleValues": _vm.setValue
    }
  })], 1) : _vm._e()], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }