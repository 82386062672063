<template>
  <MapLegendStyled>
    <VueSlider
      ref="slider"
      v-model="value"
      v-bind="options"
      :min="Math.ceil(h3WithMinValue)"
      :max="Math.ceil(h3WithMaxValue)"
      :disabled="disableSlider"
      :marks="marks"
      :interval="interval"
      @change="selectedLegendValue"
    >
      <ColorBar :background="colorBarBackground" />
      <ColorDeSelectedBarTop :height="heightBarTop" />
      <ColorDeSelectedBarBottom :height="heightBarBottom" />
    </VueSlider>
    <UnitStyled> unit: {{ selectedOverlayUnitUI }} </UnitStyled>
  </MapLegendStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

const MapLegendStyled = styled('div')`
  padding: 1rem 0 1rem 0.5rem;

  .vue-slider {
    width: 10px !important;
  }
  .vue-slider-process {
    z-index: 2;
    background-color: transparent;
  }
  .vue-slider:hover .vue-slider-process {
    background-color: transparent;
  }
  .vue-slider-mark-step {
    display: none;
  }
`
const ColorBar = styled('div')`
  position: absolute;
  height: 100%;
  width: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  background: ${({ background }) => background};
  border-radius: 8px;
`
const ColorDeSelectedBarTop = styled('div')`
  position: absolute;
  height: ${({ height }) => height};
  width: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  background: ${({ theme }) => theme.colors.mediumGrey};
  border-radius: 4px;
`
const ColorDeSelectedBarBottom = styled('div')`
  position: absolute;
  height: ${({ height }) => height};
  width: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  background: ${({ theme }) => theme.colors.mediumGrey};
  border-radius: 4px;
`
const UnitStyled = styled('div')`
  padding-top: 1rem;
  font-size: 12px;
`

export default {
  inject: ['theme'],
  props: {
    h3WithMinValue: {
      type: Number,
    },
    h3WithMaxValue: {
      type: Number,
    },
    H3AssetDataMapped: {
      type: Array,
    },
    h3HexColorsPalette: {
      type: Array,
    },
    selectedOverlayUnitUI: {
      type: String,
    },
  },
  components: {
    MapLegendStyled,
    VueSlider,
    ColorBar,
    ColorDeSelectedBarTop,
    ColorDeSelectedBarBottom,
    UnitStyled,
  },
  data() {
    return {
      value: [0, 0],
      options: {
        dotSize: [18, 6],
        width: 5,
        height: 150,
        contained: false,
        direction: 'btt',
        interval: 0.01,
        disabled: false,
        order: true,
        lazy: true,
        enableCross: false,
        tooltip: 'active',
        tooltipPlacement: 'top',
        keydownHook: null,
        dragOnClick: false,
      },
    }
  },

  computed: {
    interval() {
      const splittedArray = (this.h3WithMaxValue - this.h3WithMinValue + '').split('.')
      return splittedArray.length > 1 ? 1 / Math.pow(10, splittedArray[1].length) : 1
    },
    disableSlider() {
      return this.h3WithMaxValue === this.h3WithMinValue
    },
    colorBarBackground() {
      if (this.h3WithMaxValue === 0 && this.h3WithMinValue === 0) {
        return this.theme.colors.mediumGrey
      }
      return `linear-gradient(to top,  ${this.h3HexColorsPalette[0]} 25%, ${this.h3HexColorsPalette[1]} 50%, ${this.h3HexColorsPalette[2]} 75%, ${this.h3HexColorsPalette[3]} 100%)`
    },
    heightBarTop() {
      return (1 - this.value[1] / this.h3WithMaxValue) * 100 + '%'
    },
    heightBarBottom() {
      return (this.value[0] / this.h3WithMaxValue) * 100 + '%'
    },
    marks() {
      if (this.h3WithMaxValue === this.h3WithMinValue) {
        return [this.h3WithMaxValue, this.h3WithMinValue]
      }
      const numberOfIndexes = (this.H3AssetDataMapped.length - 1) / 4
      let indexes = []
      for (var i = 0; i < 5; i++) {
        indexes.push(Math.floor(numberOfIndexes * i))
      }
      const step = (this.h3WithMaxValue - this.h3WithMinValue) / 4
      return [
        this.h3WithMinValue,
        this.h3WithMinValue + step,
        this.h3WithMinValue + step * 2,
        this.h3WithMinValue + step * 3,
        this.h3WithMaxValue,
      ].map(value => Math.ceil(value))
    },
    minMaxArray() {
      return [this.h3WithMinValue, this.h3WithMaxValue]
    },
  },
  watch: {
    minMaxArray: {
      handler(array) {
        if (array) {
          this.$nextTick(() => {
            this.value = array.map(value => Math.ceil(value))
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    selectedLegendValue() {
      this.$emit('legendChange', this.value)
    },
  },
}
</script>
