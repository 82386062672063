var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MapLegendStyled', [_c('VueSlider', _vm._b({
    ref: "slider",
    attrs: {
      "min": Math.ceil(_vm.h3WithMinValue),
      "max": Math.ceil(_vm.h3WithMaxValue),
      "disabled": _vm.disableSlider,
      "marks": _vm.marks,
      "interval": _vm.interval
    },
    on: {
      "change": _vm.selectedLegendValue
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, 'VueSlider', _vm.options, false), [_c('ColorBar', {
    attrs: {
      "background": _vm.colorBarBackground
    }
  }), _c('ColorDeSelectedBarTop', {
    attrs: {
      "height": _vm.heightBarTop
    }
  }), _c('ColorDeSelectedBarBottom', {
    attrs: {
      "height": _vm.heightBarBottom
    }
  })], 1), _c('UnitStyled', [_vm._v(" unit: " + _vm._s(_vm.selectedOverlayUnitUI) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }