var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MapStyled', [_c('div', {
    ref: "map",
    staticClass: "map-container"
  }), _c('transition', {
    attrs: {
      "name": "slide-fade",
      "mode": "out-in"
    }
  }, [_vm.message ? _c('MessageBox', [_c('div', {
    staticClass: "message"
  }, [_c('div', [_vm._v(_vm._s(this.$tc(_vm.message)))]), _vm.showLoadingDots ? _c('LoadingDots', {
    staticClass: "dots"
  }) : _vm._e()], 1), !_vm.showLoadingDots ? _c('CloseButtonAtom', {
    staticClass: "close-button",
    on: {
      "close": _vm.closeMessageBox
    }
  }) : _vm._e()], 1) : _vm._e()], 1), _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.mapToggleSettings.showLegends && _vm.selectedHexOption && _vm.selectedHexOption.id !== 'none' ? _c('VerticalLegends', {
    key: 'map_legend',
    attrs: {
      "isSettingsExpanded": _vm.isSettingsExpanded
    }
  }, [_vm.maxLegend && !_vm.isLoading ? _c('div', {
    staticClass: "box"
  }, [_c('p', [_vm._v(_vm._s(_vm.$tc('map.max')) + " " + _vm._s(_vm.maxLegend))])]) : _vm._e(), !_vm.isLoading ? _c('MapLegend', {
    key: _vm.selectedHexOption.id,
    staticClass: "box",
    attrs: {
      "h3WithMinValue": _vm.h3WithMinValue,
      "h3WithMaxValue": _vm.h3WithMaxValue,
      "H3AssetDataMapped": _vm.H3AssetDataMapped,
      "h3HexColorsPalette": _vm.h3HexColorsPalette,
      "selectedOverlayUnitUI": _vm.selectedOverlayUnitUI
    },
    on: {
      "legendChange": _vm.h3dataFiltered
    }
  }) : _vm._e()], 1) : _vm._e()], 1), _c('TooltipStyled', {
    attrs: {
      "id": "tooltip",
      "isVisible": _vm.tooltipHex && _vm.tooltipHex.h3hex
    }
  }, [_c('div', [_vm._v("ID: " + _vm._s(_vm.tooltipHex.h3hex))]), _c('div', [_vm._v(_vm._s(_vm.tooltipHex.name) + ": " + _vm._s(_vm.tooltipHex.value))])]), _c('TooltipStyled', {
    attrs: {
      "id": "tooltip-geofence",
      "isVisible": _vm.tooltipGeofence && _vm.tooltipGeofence.name
    }
  }, [_c('div', [_vm._v("Name: " + _vm._s(_vm.tooltipGeofence.name))]), _c('div', [_vm._v("Material Type: " + _vm._s(_vm.tooltipGeofence.materialType))]), _c('div', [_vm._v("Geofence Type: " + _vm._s(_vm.tooltipGeofence.geofenceType))])]), _c('div', {
    staticClass: "assets"
  }, _vm._l(_vm.assetsWithIcons, function (asset) {
    return _c('TalpaIcon', {
      key: asset.id,
      ref: 'marker_' + asset.id,
      refInFor: true,
      staticClass: "marker",
      attrs: {
        "scope": 'AssetTypes',
        "name": asset.icon,
        "isMarker": true
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }