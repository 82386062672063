<template>
  <SubheaderStyled :class="{ scrolled: hasScrolled }">
    <TimepickerOrganism :maxSelectableIntervalInDays="92" />
    <AssetSelector />
  </SubheaderStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import TimepickerOrganism from '@/components/Atomic/Organisms/Misc/TimepickerOrganism'
import AssetSelector from '@/components/Misc/AssetSelector'

const SubheaderStyled = styled('div')`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  background: transparent;
  transition: background-color 0.25s ease;
  @media (min-width: 768px) {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }

  &.scrolled {
    background-color: ${p => chroma(p.theme.colors.solidBG).css()};
    border-bottom: 4px solid ${p => (p.theme.isDark ? 'transparent' : p.theme.colors.mainBG)};
  }
`

export default {
  props: {
    hasScrolled: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    SubheaderStyled,
    TimepickerOrganism,
    AssetSelector,
  },
}
</script>
